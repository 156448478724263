import { Empty, Rate } from "antd";
import { FormatVND } from "../../utils/format";
import { Slide } from "react-slideshow-image";
import { properties } from "../../utils/constants";
import giaohang from "../../assets/image/giaohang.png"
import { useDevice } from "../../hooks";

export default function ItemProduct({
    product, 
    grid, 
    titleSize,
    heightTitle
}) {
  const { isMobile } = useDevice();

    return (
        <div>
            {product?.length > 0 ? (
                <Slide {...properties} cssClass='rounded-xl' slidesToScroll={grid} slidesToShow={grid} indicators={false} autoplay={false}>
                    {product?.map((_, i) => (
                        <div className={`each-slide-effect ${isMobile ? "px-1" : "px-2"}`}>
                            <a href={`/san-pham/${_?.product_slug}`}>
                                <div className="border rounded-lg">
                                    <div className="flex justify-center items-center">
                                        <img src={JSON.parse(_?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data} className={`w-full ${isMobile ? "h-[w-full]" : "h-[230px]"} rounded-lg`} loading="eager" alt={_?.product_name}/>
                                    </div>
                                    <div className="p-2">
                                        <h3 className={`line-clamp-2 text-black font-medium text-[${titleSize}px] h-${heightTitle}`}>{_?.product_name}</h3>
                                        {isMobile ? (
                                            <div>
                                                <Rate style={{fontSize: 10}} allowHalf defaultValue={_?.comment_rating ? _?.comment_rating : 5} disabled/>
                                                <p className="text-[#808089] text-[10px] font-normal">Đã bán: {100 + Number(_?.product_sale)}</p>
                                            </div>
                                        ) : (
                                            <div className="flex items-center py-1">
                                                <Rate style={{fontSize: 16}} allowHalf defaultValue={_?.comment_rating ? _?.comment_rating : 5} disabled/>
                                                <p className="pl-2 ml-2 border-l text-[#808089] text-[14px] font-normal">Đã bán: {100 + Number(_?.product_sale)}</p>
                                            </div>
                                        )}
                                        <div className={`${isMobile ? "" : "py-2"}`}>
                                            {_?.product_discount ? (
                                                <>
                                                    <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price - (_?.product_price * (_?.product_discount/100)))}</p>
                                                    <div className="flex items-center">
                                                        <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{_?.product_discount}%</p>
                                                        <p className="text-[#808089] text-[10px] pl-2 line-through">{FormatVND(_?.product_price)}</p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div>
                                                    <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price)}</p>
                                                    <div className="flex items-center">
                                                        <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{_?.product_discount}%</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex border-t pt-2 mt-2">
                                            <div className="flex items-center">
                                                {!JSON.parse(_?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data ? (
                                                    <img src={giaohang} className={`${isMobile ? "h-3" : "h-5"}`} loading="eager" alt="Description"/>
                                                ): (
                                                    <img src="https://salt.tikicdn.com/ts/tka/a8/31/b6/802e2c99dcce64c67aa2648edb15dd25.png" className={`${isMobile ? "h-3" : "h-5"}`} loading="eager" alt="Description"/>
                                                )}
                                            </div>
                                            <p className="pl-2">{_?.product_time}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </Slide>
            ) : (
                <div className={`col-span-${grid}`}>
                    <Empty />
                </div>
            )}
        </div>
    )
}