import { useEffect, useState } from "react";
import { useDevice } from "../../hooks";
import { fetchAll } from "../../api/iframePage";
import { Col, Pagination, Row, Select, message } from "antd";
import { getAll } from '../../api/category';
import { fetchAllProductMkt } from '../../api/product';
import ItemProductGrid from '../../section/itemProductGrid';
import { fetchAllMakeMoney } from "../../api/affiliate";
import { FormatVND } from "../../utils/format";
import { useNavigate } from "react-router-dom";
import iconKiemTien from "../../assets/icon/kiemTien.png"

export default function ViewDetailTask() {
    const navigate = useNavigate();
    const { isMobile } = useDevice();
    const [data, setData] = useState([]);
    const [makeMoney, setMakeMoney] = useState([]);
    const [categoryChild, setCategoryChild] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState([]);
    const [topBuy, setTopBuy] = useState(false);
    const [price, setPrice] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(10);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 10,
    });

    useEffect(() => {
        let query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        fetchAll(setData);
        fetchAllMakeMoney(query, setMakeMoney);
        getAll("page=1&pageSize=100", setCategoryMenu);
    }, []);

    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        if (topBuy) { query += `&topBuy=1`; }
        if (price > 0) { query += `&filPrice=${price}`; }
        fetchAllProductMkt(query, setCategoryChild, message);
    }, [itemsToShow, topBuy, price]);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="max-w-screen-2xl mx-auto">
            <Row>
                {!isMobile && (
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {categoryMenu?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                )}
                <Col xs={24} xl={isMobile ? 24 : 20}>
                    <div className={`${isMobile ? "mt-16" : "" } my-2 bg-white rounded-xl border`}>
                        <div className="bg-[#5271ff] rounded-t-xl px-5 py-3 text-white flex justify-between items-center">
                            <div>
                                <h2 className='text-[24px] font-semibold'>Nhiệm vụ dễ dàng</h2>
                                <h3 className='text-[20px]'>Nhẹ nhàng nhận tiền triệu</h3>
                            </div>
                            <img src={iconKiemTien} width={100} height={100} />
                        </div>
                        <div className='px-5 pt-4 bg-white'>
                            {makeMoney?.data?.map((_, i) => (
                                <div className="flex justify-between items-center mb-2 border rounded-xl p-1">
                                    <img src={_?.make_money_image} width={isMobile ? 80 : 100} height={isMobile ? 80 : 100}/>
                                    <p className={isMobile ? `text-lg font-semibold px-2` : `text-xl font-bold`}>{_?.make_money_tittle}</p>
                                    <button onClick={()=> navigate("/nhiem-vu-kiem-tien/"+ _?.make_money_slug)} className={`${isMobile ? 'text-md px-2 py-1' : 'text-lg px-4 py-2'} text-white font-semibold rounded-xl bg-red-600`}>Nhận {FormatVND(_?.money)}</button>
                                </div>
                            ))}
                        </div>
                        <div className="text-center pt-5 pb-20">
                            <Pagination
                                className="flex justify-center"
                                current={pagination.page}
                                total={makeMoney?.total}
                                pageSize={pagination.pageSize}
                                showSizeChanger
                                onChange={(p, ps)=> {
                                    setPagination({
                                        page: p,
                                        pageSize: ps
                                    })
                                }}
                            />
                        </div>
                    </div>       
                </Col>
            </Row>
        </div>
    )
}