import { Empty } from "antd";
import { useNavigate } from "react-router-dom"
import { useDevice } from "../../hooks";
import parse from 'html-react-parser';

export default function ItemBlog({blog}) {
    const { isMobile } = useDevice();
        
    return (
        <div className={`grid ${isMobile ? "grid-cols-3 gap-2" : "grid-cols-4 gap-4 mx-2"}`}>
            {blog?.length > 0 ? blog?.map((_, i) => (
                <a href={`/blog/${_?.blog_slug}`} className="cursor-pointer">
                    <div className="border bg-white">
                        <div className="flex justify-center items-center">
                            <img src={_?.blog_image} className={`${isMobile ? "h-[118px]" : "h-[220px]"} w-full bg-gray-50`} alt="Description"/>
                        </div>
                        <div className={`${isMobile ? "p-1" : "p-2"}`}>
                            <p className={`${isMobile ? "text-[11px]" : "text-[14px]"} truncate h-[30px] font-semibold px-2 text-slate-800`}>{_?.blog_name}</p>
                            <p className="truncate h-[45px] px-2 text-gray-500">{parse(String(_?.blog_description)?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>"))}</p>
                        </div>  
                    </div>
                </a>
            )) : (
                <div className={`col-span-6`}>
                    <Empty />
                </div>
            )}
        </div>
    )
}