import { Table, message, Button, Row, Col, Pagination, Image, Select, Space } from "antd";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

export default function RqMakeMoneyManage() {
  const [makeMoney, setMakeMoney] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchRqMakeMoney = async () => {
    await axiosInstance.get(`/rq-make-money/getAll`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setMakeMoney(data);
      })
      .catch(() => message.error("Error server!"));
  };

  const update = async (value, id) => {
    await axiosInstance.post(`/rq-make-money/update/${id}`, {status: value})
      .then((res) => {
        fetchRqMakeMoney();
        message.success(String(res?.data?.message));
      });
  }

  const handleDelete = async (id) => {
    await axiosInstance.delete(`/rq-make-money/delete/${id}`)
      .then((res) => {
        fetchRqMakeMoney();
        message.success(String(res?.data?.message));
      });
  }

  useEffect(() => {
    fetchRqMakeMoney();
  }, [pagination]);

  const optionRole = [
    { value: 0, label: "Đang chờ" },
    { value: 1, label: "Xác nhận" },
    { value: 2, label: "Từ chối" },
  ];

  const columns = [
    {
      title: <div>ID</div>,
      key: "id",
      dataIndex: "id",
      width: 50,
      sorter: (a, b) => a.id - b.id,
      render: (_, record) => <div>{record?.id}</div>,
    },
    {
      title: <div>Tên</div>,
      key: "make_money_tittle",
      dataIndex: "make_money_tittle",
      width: 150,
      ...SearchProps("make_money_tittle"),
      render: (_, record) => <div>{record?.make_money_tittle}</div>,
    },
    {
      title: <div>Người nộp nhiệm vụ</div>,
      key: "fullname",
      dataIndex: "fullname",
      width: 150,
      ...SearchProps("fullname"),
      render: (_, record) => <div className="flex items-center">
            <img src={record?.photos} width={50}/>
            <p className="text-xl font-semibold pl-2">{record?.fullname}</p>
        </div>,
    },
    {
      title: <div>Ảnh</div>,
      key: "image",
      dataIndex: "image",
      width: 200,
      render: (_, record) => {
        const imageData = record?.image;
    
        let images = [];
    
        try {
          images = JSON.parse(imageData);
          if (!Array.isArray(images)) {
            images = [images];  
          }
        } catch (e) {
          images = [imageData]; 
        }
        return (
          <div className="flex">
            {images.map((src, index) => (
              <div className="border border-black mx-1" key={index}>
                <a href={src.trim()} target="_blank" rel="noopener noreferrer">
                  <Image src={src.trim()} width={50} height={50} />
                </a>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: <div>Lý do</div>,
      key: "reason",
      dataIndex: "reason",
      width: 150,
      render: (_, record) => <div>
          <p className="text-md pl-2">{record?.reason}</p>
        </div>,
    },
    {
      title: <div className={"base-table-cell-label"}>Trạng thái</div>,
      key: "role",
      dataIndex: "role",
      width: 200,
      filters: [
        { text: 'Đang chờ', value: 0 },
        { text: 'Xác nhận', value: 1 },
        { text: 'Từ chối', value: 2 },
      ],
      onFilter: (value, record) => {
        return record?.role === value;
      },
      render: (_, record) => {
        return (
          <div>
            <Select
              options={optionRole}
              className={"w-[200px]"}
              value={record?.status === 0 ? "Đang chờ" : record?.status === 1 ? "Xác nhận" : "Từ chối"}
              defaultValue={record?.status === 0 ? "Đang chờ" : record?.status === 1 ? "Xác nhận" : "Từ chối"}
              onChange={(value) => update(value, record?.id)}
            />
          </div>
        );
      },
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <Space>
            <Link
              to={`/admin/quan-ly-rq-make-money/${record?.id}`}
              className={"text-[var(--blue)]"}
            >
              <EditOutlined />
            </Link>
            <DeleteFilled className="text-red-600" onClick={() => handleDelete(record?.id)} />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý nộp nhiệm vụ kiếm tiền</div>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={makeMoney?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={makeMoney?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
