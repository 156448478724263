import { useNavigate, useParams } from "react-router-dom";
import { useDevice } from "../../hooks";
import { Breadcrumb, Col, Empty, Pagination, Row } from "antd";
import { useEffect, useState } from "react";
import { getByCategorySlug } from "../../api/blog";
import parse from "html-react-parser";
import dayjs from "dayjs";

export default function CategoryBlogDetail() {
    const param = useParams();
    const { isMobile } = useDevice();
    const [blog, setBlog] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 12,
    });

    useEffect(()=> {        
        getByCategorySlug(param?.slug, pagination, setBlog);
    }, [pagination])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href="/">Trang chủ</a>,
                        },
                        {
                            title: <a href="/blog">Danh mục Blog</a>,
                        },
                        {
                            title: <a href={`/danh-muc-blog/` + param?.slug}>{param?.slug}</a>,
                        }
                    ]}
                />
            </div>
            <div className="bg-white mt-5 p-5">
                <h2 className="font-bold text-xl border-b">{blog?.data?.[0]?.category_blog_name}</h2>
                {isMobile ? (
                    <div>
                        {blog?.data?.length > 0 ? blog?.data?.map((_, i) => (
                            <a href={`/blog/${_?.blog_slug}`} className="cursor-pointer">
                                <div className="grid grid-cols-3 gap-4 border bg-white my-2">
                                    <div>
                                        <img 
                                            src={_?.blog_image} 
                                            className={`w-full h-[80px] bg-gray-50`} 
                                            alt={_?.blog_name} 
                                        />
                                    </div>
                                    <div className={`col-span-2`}>
                                        <p className={`text-[14px] font-semibold p-2 text-slate-800`}>
                                            {_?.blog_name}
                                        </p>
                                        <p className={`text-[12px] font-semibold px-2 text-slate-600`}>
                                            {dayjs(_?.create_at).format("DD/MM/YYYY")} / {_?.category_blog_name}
                                        </p>
                                    </div>  
                                </div>
                            </a>
                        )) : (
                            <div className={`col-span-6`}>
                                <Empty />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={`grid ${isMobile ? "grid-cols-3 gap-2" : "grid-cols-4 gap-4 mx-2"}`}>
                        {blog?.data?.map((_,i) => (
                            <div className="py-5">
                                <a href={`/blog/${_?.blog_slug}`} className="cursor-pointer">
                                    <div className="border bg-white">
                                        <div className="flex justify-center items-center">
                                            <img src={_?.blog_image} className={`${isMobile ? "h-[118px]" : "h-[280px]"} w-full bg-gray-50`}/>
                                        </div>
                                        <div className={`${isMobile ? "p-1" : "p-2"}`}>
                                            <p className={`${isMobile ? "text-[11px]" : "text-[14px]"} truncate h-[30px] font-semibold px-2 text-slate-800`}>{_?.blog_name}</p>
                                            <p className="truncate h-[45px] px-2 text-gray-500">{parse(String(_?.blog_description)?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>"))}</p>
                                        </div>  
                                    </div>
                                </a>
                            </div>
                        )) }
                    </div> 
                )}
                <div className="mt-10">
                    <Pagination
                        className="flex justify-center"
                        current={pagination.page}
                        total={blog?.total}
                        pageSize={pagination.pageSize}
                        showSizeChanger
                        onChange={(p, ps)=> {
                            setPagination({
                                page: p,
                                pageSize: ps
                            })
                        }}
                    />
                </div>
            </div>
        </div>  
    )
}