import axiosInstance from "../utils/axios";

export const fetchAllRefundMoney = async (query, setData, message) => {
    try {
        await axiosInstance.get(`/refund-money/getAll?${query}`)
          .then((res) => {
            const data = res?.data;
            setData(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};

export const fetchRefundMoneyBySlug = async (slug, setData, message) => {
    try {
        await axiosInstance.get(`/refund-money/getBySlug/${slug}`)
          .then((res) => {
            const data = res?.data?.data[0];
            setData(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};

