import { DeleteOutlined, LogoutOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Dropdown, Image, message } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CartMini from './cardMini';
import { useEffect, useState } from 'react';
import * as CONSTANTS from "../utils/constants";
import { getCookie } from "../utils/localStorageHandle";
import { useDevice } from "../hooks";
import logout from "../assets/icon/logout.png"
import logo from "../assets/icon/logo.png"
import logoMobile from "../assets/icon/logoMobile.png"
import AutoCompleteCustom from './autoCompleteCustom';

export default function Header() {
    const { isMobile } = useDevice();
    const location = useLocation();
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [cart, setCart] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    
    const handleLogout = () => {
        localStorage.removeItem("user");
        message.success("Đăng xuất thành công!");
        navigate("/dang-nhap");
    }
    const items = [
        {
          key: "profile",
          label: (
            <Link to={'/thong-tin-ca-nhan'}>
              <div
                className="flex items-center"
              >
                <span className="cursor-pointer">Thông tin cá nhân</span>
              </div>
            </Link>
          ),
        },
        {
          key: "logout",
          label: (
            <div
              className="flex items-center"
              onClick={() => handleLogout()}
            >
              <span className="cursor-pointer">Thoát</span>
            </div>
          ),
        },
    ];

    useEffect(() => {
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
    }, [cart]);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0); // Adjust the threshold as needed
        };

        window.addEventListener('scroll', handleScroll);

        
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    return (
        <header>
            {isMobile ? (
                <>
                    {!location?.pathname?.includes("/san-pham") && !location?.pathname?.includes("/sach-cua-toi/") && (
                        <>
                            {location?.pathname === "/" && (
                                <div className='bg-blue-600 px-4 flex justify-between items-center pb-2'>
                                    <a href="/" className="flex items-center">
                                        <img src={logoMobile} className="mr-3 m-1 h-7 sm:h-9" alt="Flowbite Logo" />
                                        <span className="self-center text-xl font-medium whitespace-nowrap text-white italic">Cuahangykhoa.com</span>
                                    </a>
                                    {user && (
                                        <div className='ml-2 p-3 rounded-xl'>
                                            <img className='cursor-pointer w-[20px] h-[20px]' src={logout} onClick={handleLogout}/>
                                        </div>
                                    )}
                                </div>
                            )}
                            
                            <div className={`bg-blue-600 fixed z-20 w-full ${location?.pathname === "/" && !isScrolled ? "top-10" : "top-0"}`}>
                                <form className='px-4 py-2'>   
                                    <div class="flex items-center">
                                        <AutoCompleteCustom />
                                        {user && (
                                            <>
                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                    <div className='bg-white ml-2 p-2 h-full rounded-xl'>
                                                        <ShoppingCartOutlined className='text-2xl text-blue-600 border-gray-300 cursor-pointer' onClick={()=>navigate("/gio-hang")}/>
                                                    </div>
                                                    {cart?.length > 0 && (
                                                        <div className='w-5 h-5 text-center' style={{
                                                            position: 'absolute',
                                                            top: '-8px',
                                                            right: '-6px',
                                                            backgroundColor: 'red',
                                                            color: 'white',
                                                            borderRadius: '100%',
                                                            fontSize: '0.8rem',
                                                            fontWeight: 'bold',
                                                        }}>
                                                            {cart?.length}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </>
                        
                    )}
                </>
            ) : (
                <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-800">
                    <div className="flex flex-wrap justify-between items-center max-w-screen-2xl px-10 mx-auto pb-2">
                        <a href="/" className="flex items-center">
                            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
                            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white italic">Cuahangykhoa.com</span>
                        </a>
                        <div className='w-1/2'>
                            <AutoCompleteCustom />
                        </div>
                        {user ? (
                            <div className='flex items-center'>
                                <Dropdown placement="bottom" menu={{ items }}>
                                    <Image
                                        preview={false}
                                        src={user?.photos ? user?.photos : "https://cdn-icons-png.flaticon.com/512/3237/3237472.png"}
                                        width={40}
                                        height={40}
                                    />
                                </Dropdown>
                                
                                <Dropdown 
                                    trigger={['click']}
                                    placement="bottomRight"
                                    dropdownRender={()=>(
                                        <div className='bg-white border shadow-md rounded-lg'>
                                            <CartMini />
                                        </div>
                                    )}
                                >
                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                        <ShoppingCartOutlined className='text-2xl text-[#0b74e5] border-l-2 border-gray-300 ml-2 pl-2 cursor-pointer' />
                                        {cart?.length > 0 && (
                                            <div className='w-5 h-5 text-center' style={{
                                                position: 'absolute',
                                                top: '-10px',
                                                right: '-10px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '100%',
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold',
                                            }}>
                                                {cart?.length}
                                            </div>
                                        )}
                                    </div>
                                </Dropdown>
                            </div>
                        ) : (
                            <div className="flex items-center lg:order-2">
                                <a href="/dang-nhap" className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Đăng nhập</a>
                                <a href="/dang-ky" className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Đăng ký</a>
                                <button data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                    <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </button>
                            </div>
                        )}
                    </div>

                    <hr className='my-2'></hr>

                    <div className='max-w-screen-2xl mx-auto flex items-center'>
                        <p className='text-[14px] font-semibold text-[#003EA1] py-1'>Cam kết</p>
                        <div className='flex items-center px-5 border-r'>
                            <img src='https://salt.tikicdn.com/ts/upload/96/76/a3/16324a16c76ee4f507d5777608dab831.png' width={20} height={20}/>
                            <p className='pl-2 text-[14px] font-medium text-[#27272A]'>100% hàng thật</p>
                        </div>
                        <div className='flex items-center px-5 border-r'>
                            <img src='https://salt.tikicdn.com/ts/upload/0b/f2/19/c03ae8f46956eca66845fb9aaadeca1e.png' width={20} height={20}/>
                            <p className='pl-2 text-[14px] font-medium text-[#27272A]'>Hoàn 200% nếu hàng giả</p>
                        </div>
                        <div className='flex items-center px-5 border-r'>
                            <img src='https://salt.tikicdn.com/ts/upload/87/98/77/fc33e3d472fc4ce4bae8c835784b707a.png' width={20} height={20}/>
                            <p className='pl-2 text-[14px] font-medium text-[#27272A]'>Giao nhanh 1h</p>
                        </div>
                        <div className='flex items-center px-5'>
                            <img src='https://salt.tikicdn.com/ts/upload/6a/81/06/0675ef5512c275a594d5ec1d58c37861.png' width={20} height={20}/>
                            <p className='pl-2 text-[14px] font-medium text-[#27272A]'>Giá siêu rẻ</p>
                        </div>
                    </div>
                </nav>
            )}
        </header>
    );
}
