import { message } from "antd";
import axiosInstance from "../utils/axios";

export const fetchAllByUser = async (query, setData, message) => {
    try {
        await axiosInstance.get(`/affiliate/getAllByUser?${query}`)
          .then((res) => {
            const data = res?.data;
            setData(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
  };

export const fetchAllMakeMoney = async (query, setData, message) => {
  try {
      await axiosInstance.get(`/make-money/getAll?${query}`)
        .then((res) => {
          const data = res?.data;
          setData(data);
        })
        .catch(() => message.error("Error server!"));
  } catch (error) {
      console.log(error);
  }
};

export const getBySlug = async (slug, setData) => {
    try {
      await axiosInstance.get(`/make-money/getBySlug/${slug}`)
        .then((res) => {
          setData(res?.data?.data[0]);
        })
    } catch (error) {
        console.log(error);
    }
}