import React from 'react';
import User from '../../route/user.route';
import { StyleProvider } from '@ant-design/cssinjs'
import Header from '../header';
import Footer from '../footer';
import money from '../../assets/icon/profit.png'
import blog from '../../assets/icon/blog.png'
import speed from '../../assets/icon/speedometer.png'
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useDevice } from "../../hooks";
import BottomNav from '../bottomNav';

export default function DefaultLayoutUser() {
  const location = useLocation();
  const { isMobile } = useDevice();

  return (
    <StyleProvider hashPriority="high">
      <div className='bg-[#efefef]'>
        <Header />
        <User />
        {
          location?.pathname == "/" || 
          location?.pathname?.includes("danh-muc-trang-chu") || 
          location?.pathname?.includes("danh-muc-san-pham") ||
          location?.pathname?.includes("danh-muc-con") ||
          (!location?.pathname?.includes("/kiem-tien/") && location?.pathname?.includes("/kiem-tien")) ||
          location?.pathname?.includes("cong-cu") ||
          location?.pathname?.includes("/nhiem-vu-kiem-tien")  ? (
          <Row className='max-w-screen-2xl mx-auto'>
            <Col xs={24} xl={4}></Col>
            {isMobile && (location?.pathname?.includes("cong-cu") || location?.pathname?.includes("kiem-tien")) ? (
              <Col xs={24} xl={20}>

              </Col>
            ): (
              <Col xs={24} xl={20}>
                {!location?.pathname?.includes("/sach-cua-toi/") && (
                  <Footer />
                )}
              </Col>
            )}
          </Row>
        ): (
          <>
            {!location?.pathname?.includes("/sach-cua-toi/") && (
              <Footer />
            )}
          </>
        )}
        
        {!isMobile ? (
          <div className="fixed bottom-4 right-4 bg-blue-500 text-white p-3 rounded-lg shadow-lg hover:bg-blue-600 transition">
            <div className='text-center border-b pb-2 mb-2'>
              <a href='/blog'>
                <div className='flex justify-center'>
                  <img src={blog} width={40}/>
                </div>
                <p className='font-semibold pt-1'>Blog</p>
              </a>
            </div>
            <div className='text-center border-b pb-2 mb-2'>
              <a href='/kiem-tien'>
                <div className='flex justify-center'>
                  <img src={money} width={40}/>
                </div>
                <p className='font-semibold pt-1'>Kiếm Tiền</p>
              </a>
            </div>
            <div className='text-center'>
              <a href='/cong-cu'>
                <div className='flex justify-center'>
                  <img src={speed} width={40}/>
                </div>
                <p className='font-semibold pt-1'>Công Cụ</p>
              </a>
            </div>
          </div>
        ) : (
          <>
            {!location?.pathname?.includes("/sach-cua-toi/") && (
              <BottomNav />
            )}
          </>
        )}
        
      </div>
    </StyleProvider>
  );
};
