
import ItemCategory from '../../section/itemCategory';
import { useParams } from 'react-router-dom';
import ItemProduct from '../../section/itemProduct';
import { useEffect, useState } from 'react';
import { getAll, getBySlug } from '../../api/category';
import { Col, Empty, Row } from 'antd';
import { Slide } from 'react-slideshow-image';
import { properties } from '../../utils/constants';

export default function CategoryIsMobile() {
    const param = useParams();
    const [category, setCategory] = useState([]);
    const [categoryMenu, setCategoryMenu] = useState([]);

    useEffect(()=> {
        getBySlug(param?.slug, setCategory);
        getAll("page=1&pageSize=100", setCategoryMenu);
    }, [])
    return (
        <div className="max-w-screen-2xl mx-auto pt-[70px]">
            
            <div className='bg-white rounded-lg'>
                {categoryMenu?.data?.length > 0 ? (
                    <Slide {...properties} cssClass='rounded-xl' slidesToScroll={5} slidesToShow={5} indicators={false} autoplay={false}>
                        {categoryMenu?.data?.map((_, i) => (
                            <div className={`each-slide-effect px-1`}>
                                <a key={i} href={`/danh-muc-san-pham/${_?.category_slug}`} className='p-2 hover:bg-gray-200 rounded-xl text-center'>
                                    <div className='flex justify-center'>
                                        <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]' />
                                    </div>
                                    <p className='font-semibold text-[13px] text-black'>{_?.category_name}</p>
                                </a>
                            </div>
                        ))}
                    </Slide>
                ) : <Empty />}
            </div>

            <div className='my-2 p-5 bg-white rounded-xl'>
                <h2 className='text-[20px] font-semibold'>Khám phá theo danh mục</h2>
                <div className='py-5'>
                    <ItemCategory category={category?.data} />
                </div>
            </div>
            
            {category?.data?.dataChild?.map((_, i) => (
                <div className='my-2 p-5 bg-white rounded-xl'>
                    <div className='flex justify-between'>
                        <h3 className='font-semibold text-lg'>{_?.category_child_name}</h3>
                        <a href={`/danh-muc-con/${_?.category_child_slug}`}>
                            <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                        </a>
                    </div>
                    <div className='py-4'>
                        <ItemProduct
                            product={_?.product} 
                            grid={3} 
                            titleSize={13}
                            heightTitle={10}
                        />
                    </div>
                </div>
            ))}

        </div>
    )
}