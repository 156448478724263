import { useLocation, useNavigate, useParams } from "react-router-dom"
import ImageGallery from "../../component/imageGallery";
import ProductInfor from "../../section/productInfor";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { Form, Pagination, message } from "antd";
import parse from 'html-react-parser';
import { getAll, getAllIndexPage } from "../../api/category";
import ItemProduct from "../../section/itemProduct";
import { ArrowLeftOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import more from "../../assets/icon/more.png"
import * as CONSTANTS from "../../utils/constants";
import freeship from "../../assets/image/freeship.png";
import { getCookie } from "../../utils/localStorageHandle";
import { useDevice } from "../../hooks";
import { FormatVND } from "../../utils/format";
import { Input, Button, List, Typography, Rate } from 'antd';
import { createAffiliate } from "../../api/product";
import dayjs from "dayjs";

const { TextArea } = Input;
const { Title } = Typography;

export default function ProductIsMobile() {
    const param = useParams();
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [product, setProduct] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [isFile, setIsFile] = useState(false);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [read, setRead] = useState(false);
    const [category, setCategory] = useState([]);
    const [cart, setCart] = useState([]);
    const [comments, setComments] = useState([]);
    const [form] = Form.useForm();
    const [isBuy, setIsBuy] = useState(false);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 10,
    });  
    
    const user = JSON.parse(localStorage.getItem("user"));

    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        return {
            userReferId: searchParams.get('user_refer_id'),
            productId: searchParams.get('product_id'),
        };
    };
    const { userReferId, productId } = getQueryParams();
    
    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getBySlug/${param?.slug}`)
        .then((res) => {
            const data = res?.data?.data;
            setProduct(data);
            const image = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;
            const slide = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
            if(JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data) { setIsFile(true) }
            setProductImage([image, ...slide]);
        })
        .catch(() => message.error("Error server!"));
    };

    const handleCommentSubmit = async (value) => {
        const data = {
            ...value,
            product_id: product?.[0]?.product_id,
            create_by: user?.user_id
        }
        await axiosInstance.post(`/comment/create`, data)
        .then((res) => {
            const data = res?.data;
            message.success(String(data?.message));
            form.resetFields();
            getByProductId();
        })
        .catch(() => message.error("Error server!"));
    }

    const getByProductId = async () => {
        await axiosInstance.get(`/comment/getByProductId/` + product?.[0]?.product_id, {params: pagination})
        .then((res) => {
            const data = res?.data;
            setComments(data);
        })
        .catch(() => message.error("Error server!"));
    }

    const checkBuy = async () => {
        await axiosInstance.get(`/order/checkBuy/` + product?.[0]?.product_id, {params: {
            user_id: user?.user_id
        }})
        .then((res) => {
            const data = res?.data?.data;
            setIsBuy(data);
        })
        .catch(() => message.error("Error server!"));
    }

    useEffect(() => {
        if(user && userReferId && productId) {
            const fetchProductData = async () => {
                try {   
                    const data = {
                        userReferId: userReferId,
                        userId: user?.user_id,
                        product: productId
                    }
                    await createAffiliate(data);
                } catch (error) {
                    message.error(error.message);
                } 
            };
        
            fetchProductData();
        }
    }, [productId, userReferId, user]);

    useEffect(() => {
        fetchProduct();
        getAllIndexPage(setCategoryIndex);
    }, [isFile]);

    useEffect(() => {
        if(user) {
            checkBuy();
        }
    }, [user]);

    useEffect(() => {
        getAllIndexPage(setCategoryIndex);
        getAll("page=1&pageSize=100", setCategory);
    }, []);

    useEffect(() => {
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
    }, []);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 1); // Adjust the threshold as needed
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    useEffect(() => {
        if(product?.[0]?.product_id) {
            getByProductId();
        }
    }, [product]);
    
    return (
        <div>
            <div className="bg-white">
                {isScrolled ? (
                    <div className="bg-white fixed w-full shadow top-0 z-10 flex items-center justify-between py-2 px-4">
                        <ArrowLeftOutlined 
                            className="p-2 text-white bg-blue-600 rounded-full opacity-70" 
                            onClick={() => navigate(-1)} 
                        /> 
                        <div>
                            {user && (
                                <div style={{ position: 'relative' }}>
                                    <div 
                                        className="absolute top-0 right-10 bg-blue-600 rounded-full z-10 opacity-70" 
                                        onClick={() => navigate("/gio-hang")}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ShoppingCartOutlined className="p-2 text-white" />
                                    </div>
                                    {cart && cart.length > 0 && (
                                        <div 
                                            className="w-4 h-4 text-center" 
                                            style={{
                                                position: 'absolute',
                                                top: '-4px',
                                                right: '38px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '50%',
                                                fontSize: '0.75rem', 
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 20 
                                            }}
                                        >
                                            {cart.length}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div 
                                className="p-2 top-0 right-4 bg-blue-600 rounded-full z-10 opacity-70" 
                                onClick={() => {
                                    navigator.clipboard.writeText("https://cuahangykhoa.com" + location?.pathname);
                                    message.success("Sao chép liên kết thành công!");
                                }}
                            >
                                <img src={more} alt="More options" width={15} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="relative bg-white">
                        <div className={`${isScrolled ? 'fixed bg-blue-200 left-0 w-full shadow z-10' : ''}`}>
                            <div className="absolute top-8 left-4 bg-blue-600 rounded-full z-10 opacity-70" onClick={()=>navigate(-1)}>
                                <ArrowLeftOutlined className="p-2 text-white"/> 
                            </div>
                            {user && (
                                <div style={{ position: 'relative' }}>
                                    <div 
                                        className="absolute top-8 right-14 bg-blue-600 rounded-full z-10 opacity-70" 
                                        onClick={() => navigate("/gio-hang")}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ShoppingCartOutlined className="p-2 text-white" />
                                    </div>
                                    {cart && cart.length > 0 && (
                                        <div 
                                            className="w-4 h-4 text-center" 
                                            style={{
                                                position: 'absolute',
                                                top: '28px',
                                                right: '52px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '50%',
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 20, // Ensure the badge is above other elements
                                            }}
                                        >
                                            {cart.length}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div 
                                className="absolute top-8 right-4 bg-blue-600 rounded-full z-10 opacity-70 p-2" 
                                onClick={()=>{navigator.clipboard.writeText("https://cuahangykhoa.com" + location?.pathname); message.success("Sao chép liên kết thành công!")}}
                            >
                                <img src={more} width={15}/>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mt-[-20px]">
                    <ImageGallery product_image={productImage} />
                </div>
                <div className="bg-white">
                    <ProductInfor product={product[0]} attribute={product?.attribute} isFile={isFile}/>
                    {product[0]?.price_mkt && (
                        <div className="p-5">
                            <p className="font-semibold">Giới thiệu bạn bè mua hàng để nhận thưởng: </p>
                            <button 
                                type="submit" 
                                onClick={()=> {
                                    navigate("/kiem-tien/" + product[0]?.product_slug)
                                }}
                                className={`${isMobile ? "w-[150px]" : "w-[200px]"} mt-2 text-primary-600 bg-primary-100 border border-primary-600 hover:bg-primary-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-[16px] py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                            >
                                Nhận ngay {FormatVND(product[0]?.product_price * (product[0]?.price_mkt/100))}
                            </button>
                        </div>
                    )}
                    <div className="p-5 ml-2">
                        <p className="text-[16px] font-semibold">Thông tin chi tiết</p>
                        {parse(String(product[0]?.product_information?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                    </div>
                </div>
                    
                <div className="bg-white p-5 rounded-lg col-span-3 mt-2">
                    <p className="text-[16px] font-semibold">Đặc điểm nổi bật</p>
                    {parse(String(product[0]?.product_special?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                </div>

                <div className="bg-white p-5 rounded-lg col-span-3">
                    <div>
                        <p className="text-[16px] font-semibold">Chính sách trả hàng</p>
                        <p className="pt-1">Xem ở mục mô tả sản phẩm</p>
                    </div>
                    <div className="py-2">
                        <p className="text-[16px] font-semibold">Vận chuyển</p>
                        <div className="flex items-center pt-1">
                            <div className="flex items-center">
                                <img src={freeship} width={30} height={10}/>
                            </div>
                            <p className=" pl-2">Vào mục mã giảm giá để lấy mã freeship</p>
                        </div>
                    </div>
                </div>
                    
                <div className="bg-white p-5 rounded-lg col-span-3 mt-2">
                    <p className="text-[16px] font-semibold pb-2">Mô tả sản phẩm</p>
                    {read ? (
                        <div>
                            {parse(
                                String(product[0]?.product_description
                                ?.replaceAll('ul>', "ul className='list-disc text-start pl-8 py-4'>")
                                ?.replaceAll(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Thay thế **text** bằng <b>text</b>
                                )
                            )}
                        </div>
                    ) : (
                        <div className="truncate h-[100px]">
                            {parse(
                                String(product[0]?.product_description
                                ?.replaceAll('ul>', "ul className='list-disc text-start pl-8 py-4'>")
                                ?.replaceAll(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Thay thế **text** bằng <b>text</b>
                                )
                            )}
                        </div>
                    )}
                    <div className="flex justify-center pt-5">
                        <button className="border border-blue-600 px-4 py-2 text-lg font-semibold text-blue-600" onClick={()=> setRead(!read)}>
                            {!read ? "Xem thêm" : "Rút gọn"}
                        </button>
                    </div>
                </div>
            </div>
            <div className='my-2 p-2 bg-white rounded-xl'>
                <div style={{ padding: '20px', borderRadius: '8px', backgroundColor: "white", marginTop: 8, paddingTop: 10 }}>
                    <Title level={4} className="pb-5">Khách hàng đánh giá</Title>
                    <div className="flex items-center pb-5">
                        <p className="text-3xl font-bold">{product[0]?.comment_rating ? product[0]?.comment_rating : 5}/5</p>
                        <Rate disabled value={product[0]?.comment_rating ? product[0]?.comment_rating : 5} style={{ marginRight: '10px', paddingLeft: "10px", fontSize: 30 }} />
                    </div>
                    { user ? (
                        <Form form={form} onFinish={handleCommentSubmit}>
                            <div className="flex w-full">
                                <div>
                                    <img src={user?.photos ? user?.photos : "https://cdn-icons-png.flaticon.com/512/3237/3237472.png"} width={80} />
                                    <p className="text-lg font-semibold">{user?.fullname}</p>
                                </div>
                                <div className="w-full pl-5">
                                    <Form.Item
                                        name="comment_content"
                                        className=""
                                        rules={[{ required: true, message: 'Vui lòng nhập bình luận!' }]}
                                    >
                                        <TextArea
                                            rows={4}
                                            placeholder="Nhập bình luận của bạn..."
                                            style={{ width: '100%', borderRadius: '4px' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item
                                name="comment_rating"
                                rules={[{ required: true, message: 'Vui lòng chọn đánh giá!' }]}
                            >
                                <Rate style={{ display: 'block' }} />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: '100%', backgroundColor: '#1890ff', borderColor: '#1890ff' }}
                                >
                                    <p className="font-bold">Bình luận</p>
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <div>
                            <p>Bạn vui lòng mua sản phẩm để được đánh giá!</p>
                        </div>
                    )}
                    <List
                        header={<div className="font-semibold">Danh sách đánh giá:</div>}
                        bordered
                        dataSource={comments?.data}
                        renderItem={(item, index) => (
                            <>
                                <div className="grid grid-cols-5 p-4 border-b">
                                    <div className="flex items-start gap-4">
                                        <img src={item?.photos} width={50} height={50} className="rounded-full"/>
                                    </div>
                                    <div className="col-span-4">
                                        <div className="flex items-center">
                                            <p className="text-lg font-semibold">{item?.fullname} </p>
                                            <p className="text-md pl-2">{dayjs(item?.create_at).format("DD/MM/YYYY")} </p>
                                        </div>
                                        <p className="py-2"><Rate disabled value={item?.comment_rating} style={{ marginRight: '10px' }} /></p>
                                        <p className="text-xl font-semibold">{item?.comment_content}</p>
                                    </div>
                                </div>
                            </>
                        )}
                        style={{ marginTop: '20px' }}
                    />
                    <div className="mt-5">
                        <Pagination
                            className="flex justify-center"
                            current={pagination.page}
                            total={comments?.total}
                            pageSize={pagination.pageSize}
                            onChange={(p, ps)=> {
                                setPagination({
                                    page: p,
                                    pageSize: ps
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='my-2 p-5 bg-white rounded-xl'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <img src={categoryIndex?.data?.[0]?.category_index_image} className='h-10'/>
                    </div>
                    <a href={`/danh-muc-trang-chu/${categoryIndex?.data?.[0]?.category_index_id}`}>
                        <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                    </a>
                </div>
                <div className='py-8'>
                    <ItemProduct product={categoryIndex?.data?.[0]?.product} grid={3} titleSize={13} heightTitle={10}/>
                </div>
            </div>

            <div className='p-5 bg-white rounded-xl'>
                <div className='grid grid-cols-5 gap-4'>
                    {category?.data?.map((_,i)=> (
                        <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                            <div className='flex justify-center'>
                                <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                            </div>
                            <p className='text-center pt-1 text-[10px] font-semibold text-black'>{_?.category_name}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}