import { useEffect, useState } from "react";
import PdfView from "../../component/pdfView";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Empty, Form, Input, message } from "antd";
import { useDevice } from "../../hooks";
import parser from "html-react-parser";
import { getBySlug } from "../../api/affiliate";
import CustomUpload from "../../component/customUpload";
import axiosInstance from "../../utils/axios";

export default function MoneyDetail() {
    const param = useParams();
    const [form] = Form.useForm();
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [blog, setBlog] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));

    const onSubmit = async (value) => {
        const mergeImage = JSON.stringify(value?.image);
        const data = {
            fullname: value?.fullname,
            phone: value?.phone,
            image: mergeImage,
            make_money_id: blog?.make_money_id,
            page: 1,
            create_by: user?.user_id
        }
        await axiosInstance.post(`/rq-make-money/create`, data)
            .then((res) => {
                message.success(String(res?.data?.message));
                form.resetFields();
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    useEffect(() => {
        if(!user?.user_id) {
            navigate("/dang-nhap");
            message.info("Bạn vui lòng đăng nhập để được dùng chức năng này!")
        } else {
            getBySlug(param?.slug, setBlog);
        }
    }, []);

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href="/">Trang chủ</a>,
                        },
                        {
                            title: <a href="/kiem-tien">Kiếm tiền</a>,
                        },
                        {
                            title: <a href={`/nhiem-vu-kiem-tien` + blog?.make_money_slug}>{blog?.make_money_tittle}</a>
                        },
                    ]}
                />
            </div>
            <div className="bg-white mt-5 py-10 px-5">
                <h1 className="text-2xl font-bold">{blog?.make_money_tittle}</h1>
                <p className="text-gray-500">{parser(String(blog?.make_money_content)?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>"))}</p>         
            </div>
            <div className="bg-white mt-5 py-10 px-5 flex justify-center">
                <Form
                    layout={"vertical"}
                    colon={false}
                    form={form}
                    onFinishFailed={(e) => console.log(e)}
                    onFinish={onSubmit}
                >
            
                    <Form.Item
                        name="fullname"
                        label={"Họ và tên"}
                        rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
                    >
                        <Input type="text" placeholder="Nguyễn Văn A" />
                    </Form.Item>
            
                    <Form.Item
                        name="phone"
                        label={"Số điện thoại"}
                        rules={[{ required: true, message: "Vui lòng nhập số điện thoại!" }]}
                    >
                        <Input type="number" placeholder="09451..." />
                    </Form.Item>    
            
                    <Form.Item
                        name="image"
                        label={"Link ảnh minh chứng"}
                        rules={[{ required: true, message: "Vui lòng chọn file!" }]}
                    >
                        <CustomUpload type="image" multiple accept=".png, .jpg, .jpeg, .jfif" />
                    </Form.Item>    

                    <Button type={"primary"} htmlType={"submit"}>
                        Gửi nhiệm vụ
                    </Button>
                </Form>
            </div>
        </div>
    )
}