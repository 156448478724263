import { Table, Pagination, Image, Empty, Select, Modal, message, Input, Row, Col, Menu} from "antd";
import dayjs from "dayjs";
import { FormatVND } from "../../utils/format";
import { useDevice } from "../../hooks";
import { orderStatus } from "../../utils/constants";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { BookOutlined, ShoppingCartOutlined, UserAddOutlined } from "@ant-design/icons";


export default function HistoryRqMoney() {
    const { isMobile } = useDevice();
    const user = JSON.parse(localStorage.getItem("user"));
    const [page, setPage] = useState(0);
    const [history, setHistory] = useState(0);
    const [request, setRequest] = useState({
        request_money: 0,
        stk: "",
        bank: "",
        name: "",
        user_id: user?.user_id
    })
    const [paginationRq, setPaginationRq] = useState({
        page: 1,
        pageSize: 10,
      });

    const getAllRequestMoneyByUser = async () => {
        await axiosInstance.get(`/affiliate/getAllRequestMoneyByUser/`+ user?.user_id, {params: paginationRq})
          .then((res) => {
            const data = res?.data;
            setHistory(data);
          })
          .catch(() => message.error("Error server!"));
    }

    const handleOk = async () => {
        if(request?.request_money > 0 && request?.request_money <= user?.wallet && request?.request_money >= 50000) {
            await axiosInstance.post(`/affiliate/createRequestMoney`, request)
                .then((res) => {
                    const data = res?.data;
                    message.info(String(data?.message));
                    setRequest((prevState) => ({
                        ...prevState,
                        request_money: 0,
                    }))
                    getAllRequestMoneyByUser();
                    setPage(0);
                })
                .catch(() => message.error("Error server!"));
        } else {
            if(request?.request_money == 0) {
                message.warning("Số tiền bạn muốn rút phải lớn hơn 0!");
            } else if (request?.request_money > user?.wallet) {
                message.warning("Số tiền bạn muốn rút vượt quá số tiền trong ví!");
            } else if (request?.request_money >= 50000) {
                message.warning("Số tiền bạn muốn rút phải lớn hơn 50.000!");
            } else {
                message.warning("Số tiền bạn muốn rút không hợp lệ!");
            }
        }
    }
    
    const items = [
        {
          key: 'order',
          label: <a href="/thong-tin-ca-nhan">Đơn hàng của tôi</a>,
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: <a href="/sach-cua-toi">Sách của tôi</a>,
          icon: <BookOutlined />
        },
        {
          key: 'affiliate',
          label: <a href="/tiep-thi-lien-ket">Tiếp thị liên kết</a>,
          icon: <BookOutlined />
        },
        {
          key: 'nhiem-vu-da-nop',
          label: <a href="/nhiem-vu-da-nop">Nhiệm vụ đã nộp</a>,
          icon: <UserAddOutlined />
        },
        {
          key: 'nhiem-vu-hoan-tien-da-nop',
          label: <a href="/nhiem-vu-hoan-tien-da-nop">Nhiệm vụ hoàn tiền đã nộp</a>,
          icon: <UserAddOutlined />
        },
        {
          key: 'change-password',
          label: <a href="/doi-mat-khau">Đổi mật khẩu</a>,
          icon: <UserAddOutlined />
        }
    ];
    
    const columnHis = [
        {
            title: <div>ID</div>,
            key: "request_money_id",
            dataIndex: "request_money_id",
            sorter: (a, b) => a.request_money_id - b.request_money_id,
            render: (_, record) => <div>{record?.request_money_id}</div>,
        },
        {
            title: <div>Người yêu cầu</div>,
            key: "user_id",
            dataIndex: "user_id",
            render: (_, record) => <div>{record?.user_id} - {record?.fullname}</div>,
        },
        {
            title: <div>Số tiền yêu cầu</div>,
            key: "request_money",
            dataIndex: "request_money",
            render: (_, record) => <div>{FormatVND(record?.request_money)}</div>,
        },
        {
            title: <div>Số tài khoản nhận</div>,
            key: "stk",
            dataIndex: "stk",
            render: (_, record) => <div>{record?.stk} - {record?.bank} - {record?.name}</div>,
        },
        {
            title: <div>Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (_, record) => <div>
                {
                    record?.status == 0 ? 
                        <p className="text-lg font-semibold text-gray-600">Đang chờ</p> : 
                    record?.status == 1 ?
                        <p className="text-lg font-semibold text-green-600">Đã thanh toán</p> :
                        <p className="text-lg font-semibold text-red-600">Đã từ chối</p>
                }
            </div>,
        },
        {
            title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
            key: "create_at",
            dataIndex: "create_at",
            sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
            render: (_, record) => {
                return (
                <div className={"cursor-pointer text-[14px] font-normal"}>
                    <span className={"!inline-block min-w-[100px]"}>
                    {dayjs(record?.create_at).format("DD/MM/YYYY")}
                    </span>
                </div>
                );
            },
        }
    ];

    useEffect(() => {
        if(user) {
            getAllRequestMoneyByUser();
        }
    }, [user])

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div className="flex pb-4">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Thông tin cá nhân</a>
            </div>

            <Row>
                <Col xs={24} xl={4} className="p-1">
                    <Menu
                        className="w-full"
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col xs={24} xl={20} className="p-1 bg-white">
                    <div className="bg-white p-5">
                        <div className="flex justify-between">
                            <p className="text-lg font-semibold">Số dư của tôi: {FormatVND(user?.wallet)}</p>
                            <div className="flex items-center">
                                <p className={`${page === 2 ? "text-blue-600" : "text-black"} text-lg font-semibold cursor-pointer`} onClick={() => setPage(2)}>Rút tiền</p>
                            </div>
                        </div>

                        <div>
                            <h2 className="text-center font-semibold text-xl">Lịch sử yêu cầu rút tiền</h2>
                            
                            <div className={`grid w-full h-full mt-5 pb-20 relative`}>
                                {history?.data?.length > 0 ? (
                                    <Table
                                        className={"custom-table"}
                                        dataSource={history?.data}
                                        columns={columnHis}
                                        pagination={false}
                                    />
                                ) : (<Empty />)}
                                
                                <Pagination
                                    className="flex justify-center absolute inset-x-0 bottom-0"
                                    current={paginationRq.page}
                                    total={history?.total}
                                    pageSize={paginationRq.pageSize}
                                    showSizeChanger
                                    onChange={(p, ps)=> {
                                        setPaginationRq({
                                            page: p,
                                            pageSize: ps
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <Modal
                            title="Tạo lệnh rút tiền"
                            visible={page == 2}
                            onOk={handleOk}
                            onCancel={()=> setPage(0)}
                        >
                            <p>Lưu ý: Số tiền rút tối thiểu là {FormatVND(50000)}</p>
                            <p className="pt-5 pb-2">Số tiền cần rút:</p>
                            <Input 
                                className="mb-10"
                                placeholder="Nhập số tiền bạn muốn rút" 
                                value={request?.request_money}
                                onChange={(e)=> setRequest((prevState) => ({
                                    ...prevState,
                                    request_money: e.target.value,
                                }))
                                } 
                            />
                            <p className="pt-5 pb-2">Nhập số tài khoản:</p>
                            <Input 
                                className="mb-10"
                                placeholder="123456644888" 
                                value={request?.stk}
                                onChange={(e)=> setRequest((prevState) => ({
                                    ...prevState,
                                    stk: e.target.value,
                                }))
                                } 
                            />
                            <p className="pt-5 pb-2">Ngân hàng:</p>
                            <Input 
                                className="mb-10"
                                placeholder="Vietcombank" 
                                value={request?.bank}
                                onChange={(e)=> setRequest((prevState) => ({
                                    ...prevState,
                                    bank: e.target.value,
                                }))
                                } 
                            />
                            <p className="pt-5 pb-2">Họ và tên:</p>
                            <Input 
                                className="mb-10"
                                placeholder="Lê Văn A" 
                                value={request?.name}
                                onChange={(e)=> setRequest((prevState) => ({
                                    ...prevState,
                                    name: e.target.value,
                                }))
                                } 
                            />
                        </Modal>
                    </div>
                </Col>
            </Row>
        </div>
    )
}