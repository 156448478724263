import { Button, Form, Input, Row, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CategoryForm from "../../../component/category";
import { useCookies } from "react-cookie";
import axiosInstance from "../../../utils/axios";

export default function RqRefundMoneyManageDetail() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [data, setData] = useState({});
  const id = params?.id;

  const getById = async () => {
    await axiosInstance.get(`/rq-refund-money/getRequestById/${id}`)
        .then((res) => {
            setData(res?.data?.data[0]);
            form.setFieldsValue(res?.data?.data[0]);
        });
  }

  const onSubmit = async (values) => {
    try {
        await axiosInstance.post(`/rq-refund-money/updateRequestReason/${id}`, values)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
        navigate("/admin/quan-ly-rq-refund-money");
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getById();
  }, [id]);

  return (
    <Form
        layout={"vertical"}
        colon={false}
        form={form}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onSubmit}
    >
        <Form.Item
            label={"Số tiền"}
            name="money"
            rules={[{ required: true, message: "Vui lòng nhập số tiền!" }]}
        >
            <Input size="large" placeholder={"Nhập"} />
        </Form.Item>

        <Form.Item
            label={"Lý do"}
            name="reason"
            rules={[{ required: true, message: "Vui lòng nhập lý do!" }]}
        >
            <Input size="large" placeholder={"Nhập"} />
        </Form.Item>

        <Row gutter={40} className={"my-[40px] pl-[20px]"}>
            <Button type={"primary"} htmlType={"submit"}>
                Cập nhập
            </Button>
        </Row>
    </Form>
  );
}
