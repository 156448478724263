import { useEffect, useState } from "react";
import { useDevice } from "../../hooks";
import { fetchAll } from "../../api/iframePage";
import { Col, Pagination, Row, Select, message } from "antd";
import { getAll } from '../../api/category';
import { fetchAllProductMkt } from '../../api/product';
import ItemProductGrid from '../../section/itemProductGrid';
import { fetchAllMakeMoney } from "../../api/affiliate";
import { FormatVND } from "../../utils/format";
import { useNavigate } from "react-router-dom";
import iconKiemTien from "../../assets/icon/kiemTien.png"
import refresh from "../../assets/icon/refresh.png"
import axiosInstance from "../../utils/axios";
import { fetchAllRefundMoney } from "../../api/refundMoney";

export default function MoneyPage() {
    const navigate = useNavigate();
    const { isMobile } = useDevice();
    const [data, setData] = useState([]);
    const [makeMoney, setMakeMoney] = useState([]);
    const [refundMoney, setRefundMoney] = useState([]);
    const [categoryChild, setCategoryChild] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState([]);
    const [topBuy, setTopBuy] = useState(false);
    const [price, setPrice] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(10);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 3,
    });

    const [pagination1, setPagination1] = useState({
        page: 1,
        pageSize: 3,
      });
    
    const user = JSON.parse(localStorage.getItem("user"));

    const onRefresh = async () => {
        
        await axiosInstance.get(`/user/getWallet?user_id=${user?.user_id}`)
            .then((res) => {
                const data = res?.data;
                user.wallet = data?.data?.[0]?.wallet;
                localStorage.setItem("user", JSON.stringify(user));
                window.location.reload();
            })
            .catch(() => message.error("Error server!"));
    };

    useEffect(() => {
        let query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        fetchAll(setData);
        fetchAllMakeMoney(query, setMakeMoney);
        let query1 = `page=${pagination1?.page}&pageSize=${pagination1?.pageSize}`;
        fetchAllRefundMoney(query1, setRefundMoney, message);
        getAll("page=1&pageSize=100", setCategoryMenu);
    }, [pagination, pagination1]);

    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        if (topBuy) { query += `&topBuy=1`; }
        if (price > 0) { query += `&filPrice=${price}`; }
        fetchAllProductMkt(query, setCategoryChild, message);
    }, [itemsToShow, topBuy, price]);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };


    return (
        <div className="max-w-screen-2xl mx-auto">
            <Row>
                {!isMobile && (
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {categoryMenu?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                )}
                <Col xs={24} xl={isMobile ? 24 : 20}>
                    {user && (
                        <div className={`${isMobile ? "mt-16" : "" } m-2 p-2 bg-gray-300 border flex justify-between font-bold`}>
                            <div className="flex items-center">
                                <p className="pr-2">Số dư của tôi: {FormatVND(user?.wallet)}</p>
                                <img className="cursor-pointer" src={refresh} width={15} height={15} onClick={onRefresh} />
                            </div>
                            <div className="flex cursor-pointer" onClick={()=> navigate("/lich-su-rut-tien")}>
                                <p className="border-r border-black pr-1 mr-1">Rút tiền</p>
                                <p>Lịch sử</p>
                            </div>
                        </div>
                    )}
                                        
                    <div className={`${!user && isMobile ? "mt-16" : "" } my-2 bg-white rounded-xl border`}>
                        <div className="bg-[#5271ff] rounded-t-xl px-5 py-3 text-white flex justify-between items-center">
                            <div>
                                <h2 className='text-[24px] font-semibold'>Nhiệm vụ dễ dàng</h2>
                                <h3 className='text-[20px]'>Nhẹ nhàng nhận tiền triệu</h3>
                            </div>
                            <img src={iconKiemTien} width={100} height={100} />
                        </div>
                        <div className='px-5 pt-4 bg-white'>
                            {makeMoney?.data?.map((_, i) => (
                                <div className="flex justify-between items-center mb-2 border rounded-xl p-1">
                                    <img src={_?.make_money_image} width={isMobile ? 80 : 100} height={isMobile ? 80 : 100}/>
                                    <p className={isMobile ? `text-lg font-semibold px-2` : `text-xl font-bold`}>{_?.make_money_tittle}</p>
                                    <button onClick={()=> navigate("/nhiem-vu-kiem-tien/"+ _?.make_money_slug)} className={`${isMobile ? 'text-md px-2 py-1' : 'text-lg px-4 py-2'} text-white font-semibold rounded-xl bg-red-600`}>Nhận {FormatVND(_?.money)}</button>
                                </div>
                            ))}
                        </div>
                        <div className="text-center text-[16px] py-2">
                            <u className="cursor-pointer" onClick={()=>navigate("/nhiem-vu-kiem-tien")}>Xem thêm nhiệm vụ</u>
                        </div>
                    </div> 
                    <div className={`my-2 bg-white rounded-xl border`}>
                        <div className="bg-[#5271ff] rounded-t-xl px-5 py-3 text-white flex justify-between items-center">
                            <div>
                                <h2 className='text-[24px] font-semibold'>Hoàn tiền mua sắm</h2>
                                <h3 className='text-[20px]'>Đã rẻ nay còn rẻ hơn</h3>
                            </div>
                            <img src={iconKiemTien} width={100} height={100} />
                        </div>
                        <div className='px-5 pt-4 bg-white'>
                            {refundMoney?.data?.map((_, i) => (
                                <div className="flex items-center mb-2 border rounded-xl p-1">
                                    <img src={_?.refund_money_image} width={isMobile ? 80 : 100} height={isMobile ? 80 : 100}/>
                                    <div className="w-full px-5">
                                        <p className={isMobile ? `text-lg font-semibold p-2` : `text-xl font-bold pb-2`}>{_?.refund_money_title}</p>
                                        <button onClick={()=> navigate("/nhiem-vu-hoan-tien/"+ _?.refund_money_slug)} className={`${isMobile ? 'text-md px-2 py-1' : 'text-lg px-4 py-2'} w-full text-white font-semibold rounded-xl bg-red-600`}>Hoàn {_?.refund_money_percent} %</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center text-[16px] pb-2">
                            <u className="cursor-pointer" onClick={()=>navigate("/hoan-tien-mua-sam")}>Xem chi tiết </u>
                        </div>
                    </div>     
                    <div className={`my-2 p-5 bg-white rounded-xl`}>
                        <h1 className='text-[28px] font-semibold'>Sản phẩm tiếp thị</h1>
                        <h2 className='text-[20px] text-gray-600 font-semibold'>Lưu ý: Vui lòng chọn vào nút nhận ngay để copy liên kết tiếp thị và chia sẻ nó để được nhận tiền hoa hồng!</h2>

                        <div className='flex items-center pt-5'>
                            {!isMobile && (
                                <p className='font-semibold'>Sắp xếp theo:</p>
                            )}
                            <div className='px-2'>
                                <button 
                                    className={`border px-4 py-1 mx-4 font-normal rounded-lg ${topBuy ? "bg-blue-500 text-white" : ""}`} 
                                    onClick={() => { setTopBuy(!topBuy); setPrice(0); }}
                                >
                                    Bán Chạy
                                </button>
                                <Select
                                    className="w-[180px]"
                                    onChange={(e) => { setPrice(e); setTopBuy(false); }}
                                    options={[
                                        { label: "Hoa hồng", value: 0 },
                                        { label: "Hoa hồng: Từ thấp đến cao", value: 1 },
                                        { label: "Hoa hồng: Từ cao đến thấp", value: 2 }
                                    ]}
                                    value={price}
                                />
                            </div>
                        </div>
                        <div className='pt-4 pb-10'>
                            <ItemProductGrid
                                product={categoryChild?.data?.slice(0, itemsToShow)} 
                                grid={isMobile ? 2 : 5} 
                                titleSize={13}
                                heightTitle={10}
                            />
                        </div>
                        {itemsToShow < categoryChild?.total && (
                            <div className="flex justify-center pb-10">
                                <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                            </div>
                        )} 
                    </div>         
                </Col>
            </Row>
        </div>
    )
}