import { useEffect, useState } from "react";
import { Col, Form, Menu, Pagination, Row, Table, message, Image, Select } from "antd";
import { BookOutlined, ShoppingCartOutlined, UserAddOutlined } from "@ant-design/icons";
import { useDevice } from "../../hooks";
import axiosInstance from "../../utils/axios";
import SearchProps from "../../component/SearchProps";
import dayjs from "dayjs";
import { FormatVND } from "../../utils/format";
import refresh from "../../assets/icon/refresh.png"

export default function SendTaskRefundMoney() {
    const { isMobile } = useDevice();
    const [num, setNum] = useState(1);
    
    const user = JSON.parse(localStorage.getItem("user"));
    const [refundMoney, setRefundMoney] = useState([]);
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 10,
    });  

    const onRefresh = async () => {
        
        await axiosInstance.get(`/user/getWallet?user_id=${user?.user_id}`)
            .then((res) => {
                const data = res?.data;
                user.wallet = data?.data?.[0]?.wallet;
                localStorage.setItem("user", JSON.stringify(user));
                window.location.reload();
            })
            .catch(() => message.error("Error server!"));
    };
  
    const fetchRqRefundMoney = async () => {
      await axiosInstance.get(`/rq-refund-money/getByUserId/` + user?.user_id, {params: pagination})
        .then((res) => {
          const data = res?.data;
          setRefundMoney(data);
        })
        .catch(() => message.error("Error server!"));
    };

    const items = [
        {
          key: 'order',
          label: <a href="/thong-tin-ca-nhan">Đơn hàng của tôi</a>,
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: <a href="/sach-cua-toi">Sách của tôi</a>,
          icon: <BookOutlined />
        },
        {
          key: 'affiliate',
          label: <a href="/tiep-thi-lien-ket">Tiếp thị liên kết</a>,
          icon: <BookOutlined />
        },
        {
          key: 'nhiem-vu-da-nop',
          label: <a href="/nhiem-vu-da-nop">Nhiệm vụ đã nộp</a>,
          icon: <UserAddOutlined />
        },
        {
          key: 'nhiem-vu-hoan-tien-da-nop',
          label: <a href="/nhiem-vu-hoan-tien-da-nop">Nhiệm vụ hoàn tiền đã nộp</a>,
          icon: <UserAddOutlined />
        },
        {
          key: 'change-password',
          label: <a href="/doi-mat-khau">Đổi mật khẩu</a>,
          icon: <UserAddOutlined />
        }
    ];

    
  const column = [
    {
      title: <div>ID</div>,
      key: "id",
      dataIndex: "id",
      width: 100,
      sorter: (a, b) => a.id - b.id,
      render: (_, record) => <div>{record?.id}</div>,
    },
    {
      title: <div>Tên</div>,
      key: "refund_money_title",
      dataIndex: "refund_money_title",
      width: 150,
      ...SearchProps("refund_money_title"),
      render: (_, record) => <div>{record?.refund_money_title}</div>,
    },
    {
      title: <div>Người nộp nhiệm vụ</div>,
      key: "fullname",
      dataIndex: "fullname",
      width: 150,
      ...SearchProps("fullname"),
      render: (_, record) => <div className="flex items-center">
            <img src={record?.photos} width={50}/>
            <p className="text-xl font-semibold pl-2">{record?.fullname}</p>
        </div>,
    },
    {
      title: <div>Ảnh</div>,
      key: "image",
      dataIndex: "image",
      width: 200,
      render: (_, record) => {
        const imageData = record?.image;
    
        let images = [];
    
        try {
          images = JSON.parse(imageData);
          if (!Array.isArray(images)) {
            images = [images];  
          }
        } catch (e) {
          images = [imageData]; 
        }
        return (
          <div className="flex">
            {images.map((src, index) => (
              <div className="border border-black mx-1" key={index}>
                <a href={src.trim()} target="_blank" rel="noopener noreferrer">
                  <Image src={src.trim()} width={50} height={50} />
                </a>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Trạng thái</div>,
      key: "role",
      dataIndex: "role",
      width: 280,
      filters: [
        { text: 'Đang chờ', value: 0 },
        { text: 'Xác nhận', value: 1 },
        { text: 'Từ chối', value: 2 },
      ],
      onFilter: (value, record) => {
        return record?.role === value;
      },
      render: (_, record) => {
        return (
          <div className={`${record?.status === 0 ? "text-yellow-500" : record?.status === 1 ? "text-green-500" : "text-red-500"} text-xl font-bold`}>
            {record?.status === 0 ? "Đang chờ" : record?.status === 1 ? "Xác nhận" : "Từ chối"}
          </div>
        );
      },
    },
    {
      title: <div>Lý do</div>,
      key: "reason",
      dataIndex: "reason",
      width: 150,
      ...SearchProps("reason"),
      render: (_, record) => <div className="flex items-center">
          <p className="text-md">{record?.reason}</p>
        </div>,
    },
    {
      title: <div>Số tiền được nhận</div>,
      key: "money",
      dataIndex: "money",
      width: 150,
      render: (_, record) => <div className="flex items-center">
          <p className="text-md">{FormatVND(record?.money)}</p>
        </div>,
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    }
  ];

    useEffect(() => {
        fetchRqRefundMoney();
        window.scrollTo(0, 0);
    }, []); 

    useEffect(() => {
        fetchRqRefundMoney();
    }, [pagination]); 

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div className="flex pb-4">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="/nhiem-vu-hoan-tien-da-nop">Nhiệm vụ hoàn tiền đã nộp</a>
            </div>

            <Row>
                <Col xs={24} xl={4} className="p-1">
                    <Menu
                        className="w-full"
                        onClick={(_)=>{
                            if(_?.key == "order") {
                                setNum(1);
                            } else if (_?.key == "my-book") {
                                setNum(2);
                            } else{
                                setNum(3);
                            }
                        }}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col xs={24} xl={20} className="p-1 bg-white">
                    <div className="flex items-center p-5">
                        <p className="pr-2 text-lg font-semibold">Số dư của tôi: {FormatVND(user?.wallet)}</p>
                        <img className="cursor-pointer" src={refresh} width={15} height={15} onClick={onRefresh} />
                    </div>
                    <p className="text-2xl font-bold text-center pt-5">Nhiệm vụ hoàn tiền đã nộp</p>
                    <div className="w-full h-full mt-5 p-5 pb-20">
                        <Table
                            className={"custom-table"}
                            dataSource={refundMoney?.data}
                            columns={column}
                            pagination={false}
                        />
                        <Pagination
                            className="flex justify-center absolute inset-x-0 bottom-10"
                            current={pagination.page}
                            total={refundMoney?.total}
                            pageSize={pagination.pageSize}
                            showSizeChanger
                            onChange={(p, ps)=> {
                                setPagination({
                                    page: p,
                                    pageSize: ps
                                })
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}