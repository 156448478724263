import { AppstoreOutlined, DashboardOutlined, HomeOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import congcuden from "../assets/icon/congcuden.png"
import congcuxanh from "../assets/icon/congcuxanh.png"

export default function BottomNav() {
    const navigate = useNavigate();
    const location = useLocation();
    
    const user = JSON.parse(localStorage.getItem("user"));
    
    return (
        <nav className="fixed bottom-0 p-3 z-10 bg-white w-full flex justify-between shadow-xl border-t">
            <div className={`text-[14px] ${location?.pathname == "/" ? "text-blue-600 font-semibold" : ""}`} onClick={()=>navigate("/")}>
                <div className='flex justify-center'>
                    <HomeOutlined />
                </div>
                Trang chủ
            </div>
            <div className={`text-center text-[14px] ${location?.pathname?.includes("danh-muc-san-pham") ? "text-blue-600 font-semibold" : ""}`} onClick={()=>navigate("/danh-muc-san-pham/Sach-and-Tai-Lieu")}>
                <div className='flex justify-center'>
                    <AppstoreOutlined />
                </div>
                Danh mục
            </div>
            <div className={`text-center text-[14px] ${location?.pathname?.includes("/cong-cu") ? "text-blue-600 font-semibold" : ""}`} onClick={()=>navigate("/cong-cu")}>
                <div className='flex justify-center'>
                    <img src={location?.pathname?.includes("/cong-cu") ? congcuxanh : congcuden} width={20}/>
                </div>
                Công cụ
            </div>
            <div className={`text-center text-[14px] ${location?.pathname?.includes("/kiem-tien") ? "text-blue-600 font-semibold" : ""}`} onClick={()=>navigate("/kiem-tien")}>
                <div className='flex justify-center'>
                    <MessageOutlined />
                </div>
                Kiếm tiền
            </div>
            <div 
                className={
                    `text-center text-[14px] 
                    ${location?.pathname?.includes("/thong-tin-ca-nhan") || 
                        location?.pathname?.includes("/sach-cua-toi") || 
                        location?.pathname?.includes("/dang-nhap") ? "text-blue-600 font-semibold" : ""}`
                    } 
                onClick={()=>user ? navigate("/thong-tin-ca-nhan") : navigate("/dang-nhap")}
            >
                <div className='flex justify-center'>
                    <UserOutlined />
                </div>
                Tài khoản
            </div>
        </nav>
    );
};